import WorkShiftTimeUtil from '../../util/workShiftTime.util';
import css from './OrderBreakdown.module.scss';
import { FormattedMessage } from 'react-intl';

const LineItemWorkShift = props => {
  const { transaction } = props;

  if (!transaction) return null;

  const attributes = transaction?.attributes;
  const protectedData = attributes?.protectedData;
  const shiftStartTime = protectedData?.shiftStartTime;
  const shiftEndTime = protectedData?.shiftEndTime;

  if (!shiftStartTime || !shiftEndTime) return null;

  return (
    <div className={css.workShiftSection}>
      <div className={css.rowUnsetMarginLR}>
        <div className={css.col3}>
          <label>
            <FormattedMessage id="FieldDateRangeInput.workShift.label" />{' '}
          </label>
        </div>
        <div className={css.col9}>
          <span className={css.startEndTime}>
            <FormattedMessage
              id="FieldDateRangeInput.workShift.startAndEndTime"
              values={{
                shiftStartTime: WorkShiftTimeUtil.convertToReadableTime(shiftStartTime),
                shiftEndTime: WorkShiftTimeUtil.convertToReadableTime(shiftEndTime),
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default LineItemWorkShift;
