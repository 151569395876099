import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { ES_LOCALE, FR_LOCALE, getLocalStorageLocale } from '../../util/types';
import { camelize } from './../../util/string';

const LANDING_PAGE = 'landing-page';

export let ASSET_NAME = LANDING_PAGE;

export const loadData = (params, search, config) => dispatch => {
  const { locale } = config.localization;

  console.log(`LandingPage locale`, locale);

  const localStorageLocale = getLocalStorageLocale();
  console.log(`localStorageLocale`, localStorageLocale);
  if (localStorageLocale === FR_LOCALE || localStorageLocale === ES_LOCALE) {
    ASSET_NAME = LANDING_PAGE + '-' + localStorageLocale;
  }

  const pageAsset = { [camelize(ASSET_NAME)]: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
