import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import {
  ACCOUNT_TYPE_AGENT,
  ACCOUNT_TYPE_BUSINESS,
  ACCOUNT_TYPE_WORKER,
  propTypes,
} from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';
import TopbarFormLinksEnum from '../../../enums/topbar-links.enum';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  const publicData = currentUser?.attributes?.profile?.publicData;
  const privateData = currentUser?.attributes?.profile?.privateData;
  const profileAccountType = publicData?.profileAccountType;
  const dashboardUrl = privateData?.dashboardUrl;

  const isAgent = profileAccountType === ACCOUNT_TYPE_AGENT;
  const isBusiness = profileAccountType === ACCOUNT_TYPE_BUSINESS;
  const isWorker = profileAccountType === ACCOUNT_TYPE_WORKER;

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = authenticatedOnClientSide ? (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  ) : (
    <div className={css.searchLink}></div>
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const userFormLink = (
    <MenuItem key="userForm">
      {authenticatedOnClientSide && isAgent && (
        <a
          className={classNames(css.yourListingsLink)}
          onClick={() => window.open(TopbarFormLinksEnum.userForm, '_blank')}
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.userForm" />
        </a>
      )}
    </MenuItem>
  );

  const talentFeedbackFormLink = (
    <MenuItem key="talentFeedbackForm">
      {authenticatedOnClientSide && isWorker && (
        <a
          className={classNames(css.yourListingsLink)}
          onClick={() => window.open(TopbarFormLinksEnum.talentFeedbackForm, '_blank')}
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.talentFeedbackForm" />
        </a>
      )}
    </MenuItem>
  );

  const businessFeedbackFormLink = (
    <MenuItem key="businessFeedbackForm">
      {authenticatedOnClientSide && isBusiness && (
        <a
          className={classNames(css.yourListingsLink)}
          onClick={() => window.open(TopbarFormLinksEnum.businessFeedbackForm, '_blank')}
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.businessFeedbackForm" />
        </a>
      )}
    </MenuItem>
  );

  const bookADemoCallLink = !authenticatedOnClientSide ? (
    <a
      className={css.inboxLink}
      onClick={() => window.open(TopbarFormLinksEnum.bookADemoCall, '_blank')}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.bookADemoCall" />
      </span>
    </a>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const dashboardLink =
    authenticatedOnClientSide && dashboardUrl ? (
      <NamedLink className={css.inboxLink} name="DashboardPage">
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.dashboard" />
          {notificationDot}
        </span>
      </NamedLink>
    ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          {profileAccountType !== ACCOUNT_TYPE_AGENT &&
          profileAccountType !== ACCOUNT_TYPE_WORKER ? (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          ) : null}
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {userFormLink}
        {talentFeedbackFormLink}
        {businessFeedbackFormLink}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {search}
      <LanguageSwitcher isAuthenticated={isAuthenticated} />
      {profileAccountType === ACCOUNT_TYPE_BUSINESS && (
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            {profileAccountType === ACCOUNT_TYPE_BUSINESS && (
              <FormattedMessage id="TopbarDesktop.createJobPosting" />
            )}
            {/* {profileAccountType === ACCOUNT_TYPE_WORKER && (
              <FormattedMessage id="TopbarDesktop.createWorkerListing" />
            )} */}
          </span>
        </NamedLink>
      )}
      {bookADemoCallLink}
      {dashboardLink}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
