import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { FR_LOCALE, ES_LOCALE, getLocalStorageLocale } from '../../util/types';

export let PAGE_ID;

export const loadData = (params, search, config) => dispatch => {
  const { pageId } = params;

  const pageIdChars = pageId.split('-');
  if (pageIdChars.includes(FR_LOCALE) || pageIdChars.includes(ES_LOCALE)) {
    PAGE_ID = pageId;
  } else {
    // const { locale } = config.localization;
    const locale = getLocalStorageLocale();
    if (locale === FR_LOCALE || locale === ES_LOCALE) {
      PAGE_ID = pageId + '-' + locale;
    } else {
      PAGE_ID = pageId;
    }
  }

  const pageAsset = { [PAGE_ID]: `content/pages/${PAGE_ID}.json` };
  const hasFallbackContent = false;
  return dispatch(fetchPageAssets(pageAsset, hasFallbackContent));
};
