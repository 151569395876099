import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { ACCOUNT_TYPE_BUSINESS, ACCOUNT_TYPE_WORKER, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { AvatarLarge, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.scss';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    profileAccountType,
    businessName,
    departmentNumber,
    locationNumber,
  } = author?.attributes?.profile?.publicData;
  const isBusiness = profileAccountType === ACCOUNT_TYPE_BUSINESS;
  const isWorker = profileAccountType === ACCOUNT_TYPE_WORKER;
  const createdAt = author.attributes.createdAt;
  const memberSince = createdAt ? moment(createdAt).format('MMM YYYY') : null;
  const listingDisplayName = isBusiness ? businessName : authorName;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.info}>
        {showAuthorInfo && <AvatarLarge user={author} disableProfileLink={true} />}
        <div className={css.col6}>
          <div className={css.mainInfo}>
            {showAuthorInfo ? (
              <div className={css.authorInfo}>
                <FormattedMessage
                  id="ListingCard.author"
                  values={{ authorName: listingDisplayName }}
                />
              </div>
            ) : null}
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {showAuthorInfo && (
              <div className={css.dataAttrSection}>
                {profileAccountType && (
                  <div className={css.dataAttr}>
                    <strong>
                      <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-user'} />
                      <FormattedMessage id="ListingCard.profileAccountType" />{' '}
                    </strong>
                    {isBusiness && <FormattedMessage id="SignupForm.profileAccountType.business" />}
                    {isWorker && <FormattedMessage id="SignupForm.profileAccountType.worker" />}
                  </div>
                )}
                {isWorker && memberSince && (
                  <div className={css.dataAttr}>
                    <strong>
                      <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-calendar'} />
                      <FormattedMessage id="ListingCard.memberSince" />{' '}
                    </strong>
                    {memberSince}
                  </div>
                )}
                {isBusiness && locationNumber && (
                  <div className={css.dataAttr}>
                    <strong>
                      <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-location-dot'} />
                      <FormattedMessage id="ListingCard.locationNumber" />{' '}
                    </strong>
                    {locationNumber}
                  </div>
                )}
                {isBusiness && departmentNumber && (
                  <div className={css.dataAttr}>
                    <strong>
                      <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-building'} />
                      <FormattedMessage id="ListingCard.departmentNumber" />{' '}
                    </strong>
                    {departmentNumber}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
