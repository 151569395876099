export const validateAgentCode = agentCode => {
  console.log(`agentCode`, agentCode);
  if (
    agentCode &&
    agentCode.trim().length > 0 &&
    agentCode.length > 0 &&
    agentCode !== null &&
    agentCode !== 'null' &&
    agentCode !== undefined &&
    agentCode !== 'undefined' &&
    agentCode !== NaN
  ) {
    return true;
  } else {
    return false;
  }
};
