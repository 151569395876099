import css from './OrderBreakdown.module.scss';
import { FormattedMessage } from 'react-intl';

const LineItemUnpaidBreak = props => {
  const { transaction } = props;

  if (!transaction) return null;

  const attributes = transaction?.attributes;
  const protectedData = attributes?.protectedData;
  const unpaid_break = protectedData?.unpaid_break;

  if (!unpaid_break) return null;

  const unpaidBreakChars = unpaid_break.split('_');
  const unpaidBreakValue =
    unpaidBreakChars && unpaidBreakChars.length > 0 ? unpaidBreakChars[0] : null;

  return unpaidBreakValue ? (
    <div className={css.unpaidBreaktSection}>
      <div className={css.rowUnsetMarginLR}>
        <div className={css.col6}>
          <label>
            <FormattedMessage id="LineItemUnpaidBreak.label" />{' '}
          </label>
        </div>
        <div className={css.col6}>
          <span className={css.startEndTime}>
            <FormattedMessage
              id="LineItemUnpaidBreak.time"
              values={{
                time: unpaidBreakValue,
              }}
            />
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default LineItemUnpaidBreak;
