import React from 'react';
import css from './LanguageSwitcher.module.scss';
import {
  EN_LOCALE,
  ES_LOCALE,
  FR_LOCALE,
  SELECTED_BROWSER_LANGUAGE,
  getLocalStorageLocale,
} from '../../util/types';
import { bool } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import { useDispatch } from 'react-redux';
import { updateProfileLocale } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import disableSpanish from '../../config/configMultiLanguage';

const LanguageSwitcher = injectIntl(props => {
  const { intl, isAuthenticated } = props;
  // const { locale } = intl;
  const locale = getLocalStorageLocale();
  console.log(`LanguageSwitcher locale`, locale);

  const dispatch = useDispatch();

  const changeLanguage = e => {
    if (e.target.value === '') return;

    let newLocale = e.target.value;
    switch (e.target.value) {
      case 'en-US':
      case 'en-CA':
        newLocale = EN_LOCALE;
        break;
    }
    console.log(`newLocale`, newLocale);
    if (isAuthenticated) {
      dispatch(updateProfileLocale({ protectedData: { profileLocale: newLocale } })).then(() => {
        window.location.reload();
      });
    } else {
      localStorage.setItem(SELECTED_BROWSER_LANGUAGE, newLocale);
      if (localStorage.getItem(SELECTED_BROWSER_LANGUAGE) === newLocale) {
        window.location.reload();
      } else {
        console.error('Could not set language in local storage.');
      }
    }
  };

  return (
    <div className={css.languageSwitcher}>
      <select onChange={changeLanguage}>
        {locale !== 'en-US' && locale !== 'en-CA' && <option value={EN_LOCALE}>EN</option>}
        {locale === 'en-US' && <option value={'en-US'}>EN (US)</option>}
        {locale === 'en-CA' && <option value={'en-CA'}>EN (CA)</option>}
        <option value={FR_LOCALE} selected={locale === FR_LOCALE}>
          FR
        </option>
        {!disableSpanish && <option value={ES_LOCALE}>ES</option>}
      </select>
    </div>
  );
});

LanguageSwitcher.defaultProps = {
  isAuthenticated: false,
};

LanguageSwitcher.propTypes = {
  isAuthenticated: bool.isRequired,
};

export default LanguageSwitcher;
