import { Page } from '../../components';

import englishMessages from '../../translations/en.json';
import frenchMessages from '../../translations/fr.json';
import spanishMessages from '../../translations/es.json';
import emailTexts from '../../translations/email-texts.json';

import { EN_LOCALE, ES_LOCALE, FR_LOCALE } from '../../util/types';

export const BlankPage = () => {
  const show = 'email';

  // ENGLISH
  const sortedEnglishMessages = new Array();
  Object.keys(englishMessages)
    .sort()
    .forEach(function(v, i) {
      sortedEnglishMessages.push(v);
    });
  const filteredEnglishMessages = new Array();
  const englishSet = new Set();
  for (let i = 0; i < sortedEnglishMessages.length; i++) {
    const key = sortedEnglishMessages[i];
    if (!englishSet.has(key)) {
      filteredEnglishMessages.push(`"${key}": "${englishMessages[key]}"`);
      englishSet.add(key);
    }
  }

  // FRENCH
  const sortedFrenchMessages = new Array();
  Object.keys(frenchMessages)
    .sort()
    .forEach(function(v, i) {
      sortedFrenchMessages.push(v);
    });
  const filteredFrenchMessages = new Array();
  const frenchSet = new Set();
  for (let i = 0; i < sortedFrenchMessages.length; i++) {
    const key = sortedFrenchMessages[i];
    if (englishSet.has(key)) {
      filteredFrenchMessages.push(`"${key}": "${frenchMessages[key]}"`);
      frenchSet.add(key);
    }
  }
  for (const enKey of englishSet) {
    if (!frenchSet.has(enKey)) {
      filteredFrenchMessages.push(`"${enKey}": "${englishMessages[enKey]}"`);
      frenchSet.add(enKey);
    }
  }
  filteredFrenchMessages.sort();

  // SPANISH
  const sortedSpanishMessages = new Array();
  Object.keys(spanishMessages)
    .sort()
    .forEach(function(v, i) {
      sortedSpanishMessages.push(v);
    });
  const filteredSpanishMessages = new Array();
  const spanishSet = new Set();
  for (let i = 0; i < sortedSpanishMessages.length; i++) {
    const key = sortedSpanishMessages[i];
    if (englishSet.has(key)) {
      filteredSpanishMessages.push(`"${key}": "${spanishMessages[key]}"`);
      spanishSet.add(key);
    }
  }
  for (let enKey of englishSet) {
    if (!spanishSet.has(enKey)) {
      filteredSpanishMessages.push(`"${enKey}": "${englishMessages[enKey]}"`);
      spanishSet.add(enKey);
    }
  }
  filteredSpanishMessages.sort();

  // EMAIL
  const sortedEmailTexts = new Array();
  Object.keys(emailTexts)
    .sort()
    .forEach(function(v, i) {
      sortedEmailTexts.push(v);
    });

  const filteredEmailTexts = new Array();

  const emailSetEn = new Set();
  for (let i = 0; i < sortedEmailTexts.length; i++) {
    const key = sortedEmailTexts[i];
    if (!emailSetEn.has(key)) {
      filteredEmailTexts.push(`"${key}": "${emailTexts[key]}"`);
    }
  }
  const emailSetFr = new Set();
  for (let i = 0; i < sortedEmailTexts.length; i++) {
    const key = sortedEmailTexts[i];
    const keyWithLocale = `${key}.fr`;
    if (!emailSetFr.has(keyWithLocale)) {
      filteredEmailTexts.push(`"${keyWithLocale}": "${emailTexts[key]}"`);
      emailSetFr.add(keyWithLocale);
    }
  }
  const emailSetEs = new Set();
  for (let i = 0; i < sortedEmailTexts.length; i++) {
    const key = sortedEmailTexts[i];
    const keyWithLocale = `${key}.es`;
    if (!emailSetEs.has(keyWithLocale)) {
      filteredEmailTexts.push(`"${keyWithLocale}": "${emailTexts[key]}"`);
      emailSetEs.add(keyWithLocale);
    }
  }
  filteredEmailTexts.sort();

  return (
    <Page title={'Blank page'}>
      {show === EN_LOCALE &&
        filteredEnglishMessages.map(key => (
          <>
            <span>{key},</span>
          </>
        ))}
      {show === FR_LOCALE &&
        filteredFrenchMessages.map(key => (
          <>
            <span>{key},</span>
          </>
        ))}
      {show === ES_LOCALE &&
        filteredSpanishMessages.map(key => (
          <>
            <span>{key},</span>
          </>
        ))}
      {show === 'email' &&
        filteredEmailTexts.map(key => (
          <>
            <span>{key},</span>
          </>
        ))}
    </Page>
  );
};
