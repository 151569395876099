/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import {
  ACCOUNT_TYPE_AGENT,
  ACCOUNT_TYPE_BUSINESS,
  ACCOUNT_TYPE_WORKER,
  propTypes,
} from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';
import TopbarFormLinksEnum from '../../../enums/topbar-links.enum';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const publicData = currentUser?.attributes?.profile?.publicData;
  const privateData = currentUser?.attributes?.profile?.privateData;
  const profileAccountType = publicData?.profileAccountType;
  const dashboardUrl = privateData?.dashboardUrl;

  const isAgent = profileAccountType === ACCOUNT_TYPE_AGENT;
  const isBusiness = profileAccountType === ACCOUNT_TYPE_BUSINESS;
  const isWorker = profileAccountType === ACCOUNT_TYPE_WORKER;

  const mobileLanguageSwitcher = (
    <div className={css.row}>
      <div className={css.col12}>
        <div className={css.mobileLanguageSwitcherRow}>
          <LanguageSwitcher isAuthenticated={isAuthenticated} />
        </div>
      </div>
    </div>
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        {mobileLanguageSwitcher}
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          {/* <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink> */}
          <a
            className={css.createNewListingLink}
            onClick={() => window.open(TopbarFormLinksEnum.bookADemoCall, '_blank')}
          >
            <FormattedMessage id="TopbarMobileMenu.bookADemoCall" />
          </a>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      {mobileLanguageSwitcher}
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {/* <a className={classNames(css.inbox)} onClick={() => window.open(dashboardUrl, '_blank')}> */}
        {dashboardUrl && (
          <NamedLink
            className={classNames(css.inbox, currentPageClass('DashboardPage'))}
            name="DashboardPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <FormattedMessage id="TopbarMobileMenu.dashboardLink" />
            {notificationCountBadge}
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {profileAccountType === ACCOUNT_TYPE_BUSINESS && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        {isAgent && (
          <a
            className={classNames(css.inbox)}
            onClick={() => window.open(TopbarFormLinksEnum.userForm, '_blank')}
          >
            <FormattedMessage id="TopbarMobileMenu.userForm" />
          </a>
        )}
        {isWorker && (
          <a
            className={classNames(css.inbox)}
            onClick={() => window.open(TopbarFormLinksEnum.talentFeedbackForm, '_blank')}
          >
            <FormattedMessage id="TopbarMobileMenu.talentFeedbackForm" />
          </a>
        )}
        {isBusiness && (
          <a
            className={classNames(css.inbox)}
            onClick={() => window.open(TopbarFormLinksEnum.businessFeedbackForm, '_blank')}
          >
            <FormattedMessage id="TopbarMobileMenu.businessFeedbackForm" />
          </a>
        )}
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        {isBusiness && (
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        )}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
